import { useStaticQuery, graphql } from 'gatsby'

export const useCompassItems = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "4MCMvETed5aN5AY2eLDq0m" } }
      ) {
        data: edges {
          node {
            meta {
              pre
            }
            description
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1200)
                }
              }
            }
            sectionModules {
              ... on ContentfulMxModules {
                contentSlots {
                  photos {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: BLURRED
                          width: 600
                          formats: [PNG]
                        )
                      }
                    }
                  }
                }
                moduleTitle
                moduleMisc {
                  action {
                    text
                    url
                  }
                  benefits {
                    comparable
                    kitchen
                    name
                    position
                    purchase
                    quote
                    sale
                  }
                  description
                  isLeft
                  name
                  position
                  quote
                }
                images {
                  file {
                    url
                  }
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, width: 1200)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}
