import React from 'react'

import 'twin.macro'
import { Section } from '../section'

interface Props {
  name?: string
  position?: string
  quote?: string
}
export const SalesQuote = ({ name, position, quote }: Props) => {
  return (
    <Section padding="none">
      <div tw="relative">
        <div tw="flex flex-col flex-col-reverse justify-end pr-0 md:(flex-row pr-4)">
          <div tw="w-full p-6 bg-gray-100 text-gray-700 md:(w-8/12)">
            <h2 tw="font-thin leading-tight text-3xl md:(leading-tighter text-4xl)">
              “{quote}”
            </h2>
            <div tw="text-lg max-w-xs capitalize font-light leading-normal my-4 pl-4 border-l md:max-w-md">
              <div tw="text-lg">{name}</div>
              <div tw="text-sm pt-2">{position}</div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SalesQuote
