import React from 'react'

import 'twin.macro'

import { FormContactSource } from 'types/crm'

import { SubmitCompass } from './submit-compass'
import { Section } from '../section'

interface Props {
  description?: string
  quote?: string
  title?: string
}
export const SubmitBottom = ({ description, quote, title }: Props) => {
  return (
    <Section padding="normal">
      <div tw="relative">
        <div tw="flex flex-col justify-end pl-4 lg:pl-10">
          {quote && (
            <div tw="w-full text-gray-700 py-4 md:(w-1/2)">
              <h2 tw="font-thin leading-tight text-3xl md:(leading-tighter text-4xl)">
                {quote}
              </h2>
            </div>
          )}
          <div tw="w-full text-gray-700 md:(w-1/2)">
            <SubmitCompass
              contactSource={FormContactSource.COMPASS}
              description={description}
              title={title}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SubmitBottom
