import React, { SyntheticEvent, useContext, useState } from 'react'

import { ArrowRightIcon } from '@radix-ui/react-icons'
import 'twin.macro'

import useLeadMutate from 'context/lead/use-mutate'
import fireEvent from 'context/tracking/events'
import {
  getShareASaleClickId,
  TrackingContext,
} from 'context/tracking/provider'
import checkGIF from 'images/submit/check.gif'
import { FormContactSource } from 'types/crm'
import { emailIsValid } from 'views/utils'

import { MxButton as Button } from '../components/section-components'

interface Props {
  buttonText?: string
  contactSource?: FormContactSource
  description?: string
  title?: string
}

const SubmitCompass = ({
  buttonText,
  contactSource,
  description,
  title,
}: Props) => {
  const [email, setEmail] = useState<string>('')
  const [completedUpsertLead, setCompletedUpsertLead] = useState<boolean>(false)
  const { state: utmState } = useContext(TrackingContext)

  const {
    upsertLead,
    loading: loadingUpsertLead,
    error,
  } = useLeadMutate({
    onCompleted: ({ upsertOneLead }) => {
      if (upsertOneLead?.hubspotId) {
        fireEvent({
          type: 'identify',
          userId: upsertOneLead.hubspotId,
          email,
        })
        fireEvent({
          type: 'lead_upserted',
          hubspotId: upsertOneLead.hubspotId,
        })
      }
      setCompletedUpsertLead(true)
    },
    onError: (error) => {
      console.error('Email compass sign-up error: ', error)
    },
  })

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (!loadingUpsertLead) {
      const lead = {
        data: {
          form_contact_source: contactSource,
          shareASaleTrackingCode: getShareASaleClickId(),
        },
        dataTracking: {
          utmCampaign: utmState.utmccn,
          utmContent: utmState.utmcct,
          utmMedium: utmState.utmcmd,
          utmSource: utmState.utmcsr,
          utmTerm: utmState.utmctr,
          gclid: utmState.gclid,
        },
        email,
      }
      await upsertLead({
        variables: {
          create: lead,
          update: {},
          where: {
            email,
          },
        },
      })
    }
  }
  const onChangeProspectEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  return (
    <div tw="flex flex-col md:(flex-row)">
      <div tw="w-full pr-3 pb-8 flex flex-col justify-around order-2 md:(order-1 w-4/5 pl-0 pt-4 )">
        {(title || description) && (
          <div tw="mt-4 md:(mt-0 mb-0)">
            <p tw="font-thin leading-tight text-4xl max-w-xs w-5/6 pb-6 md:(font-light max-w-lg w-auto leading-tighter)">
              {title}
            </p>
            <p tw="text-gray-700 text-lg max-w-md font-light leading-normal">
              {description}
            </p>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div tw="space-y-4 pt-6 md:pt-8">
            <div tw="mt-1 space-y-2">
              <input
                id="email"
                name="email"
                placeholder="Enter your email to get started"
                onChange={(e) => onChangeProspectEmail(e)}
                required
                value={email}
                type="email"
                autoComplete="email"
                tw="bg-white text-base block px-3 py-2 w-full shadow-sm border border-gray-300 rounded-sm placeholder:text-base focus:(ring-gray-100 border-gray-700)"
              />
              {error && (
                <p tw="text-base text-gray-500">
                  Please enter a valid email address
                </p>
              )}
            </div>
            <div tw="items-start">
              <Button
                loading={loadingUpsertLead}
                color="default"
                iconRight={<ArrowRightIcon tw="relative top-0.5" />}
                size="marketing"
                htmlType="submit"
                disabled={!emailIsValid(email)}
              >
                <span>{buttonText || 'Submit'}</span>
              </Button>
              {completedUpsertLead && (
                <div tw="inline p-4">
                  <img tw="inline w-9" src={checkGIF} alt="Validated email" />
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export { SubmitCompass }
