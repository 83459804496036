import React from 'react'

import { BLOCKS } from '@contentful/rich-text-types'
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import { GatsbyImage, getSrc, IGatsbyImageData } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import ReactCompareImage from 'react-compare-image'
import { styled } from 'twin.macro'

import { Section } from '../section'
//TODO: REMOVE UNUSED COMPONENT
export const BeforeAfterSlider = ({
  beforeImage,
  afterImage,
}: {
  beforeImage: IGatsbyImageData
  afterImage: IGatsbyImageData
}) => {
  return (
    <SliderContainer>
      <ReactCompareImage
        handle={
          <SliderHandle>
            <ChevronLeftIcon />
            <ChevronRightIcon />
          </SliderHandle>
        }
        rightImage={getSrc(beforeImage)}
        leftImage={getSrc(afterImage)}
        sliderLineWidth={5}
        sliderPositionPercentage={0.7}
      />
    </SliderContainer>
  )
}

const SliderContainer = styled.section`
  img {
    margin: 0;
  }
`

const SliderHandle = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-radius: 100%;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  font-size: 11px;
  height: 38px;
  justify-content: space-between;
  width: 38px;
  padding: 8px;
`

const DesktopTestimonialCard = ({
  client,
  location,
  photo,
  markdown,
}: {
  testimonial: string
  client: string
  location: string
  photo: IGatsbyImageData
  markdown?: any
}) => {
  return (
    <div tw="hidden md:(block py-6)">
      <div tw="py-4 px-6">
        <blockquote tw="flex justify-evenly items-center">
          <footer tw="flex space-x-3 items-center lg:(space-x-0 items-start flex-row)">
            <div tw="w-9 h-full overflow-hidden rounded-full">
              <GatsbyImage alt="kitchen design" image={photo} />
            </div>
            <div tw="font-light pl-2">
              <p tw="text-sm text-gray-500">{client}</p>
              <p tw="text-xs text-gray-400">{location}</p>
            </div>
          </footer>
          <div>
            {markdown &&
              renderRichText(markdown, {
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => {
                    if (node.nodeType === 'paragraph') {
                      return (
                        <p tw="font-thin text-xl leading-snug text-gray-700 max-w-2xl">
                          {children}
                        </p>
                      )
                    }
                  },
                },
              })}
          </div>
        </blockquote>
      </div>
    </div>
  )
}

const Renderings = ({ content }: { content: any }) => {
  if (!content) {
    return null
  }

  const renderings = content?.[0]?.node?.sectionModules?.[2].contentSlots?.[0]

  const {
    slotTitle: title,
    meta: { testimonial, client, location },
    photos,
    markdown,
  } = renderings

  return (
    <Section tw="mt-44" padding="none">
      <div tw="relative px-6 md:(px-8)">
        <div tw="flex flex-col justify-between">
          <div tw="py-8 w-full md:(py-12)">
            <h2 tw="text-4xl font-thin text-black leading-tighter max-w-full md:(text-center)">
              {title}
            </h2>
          </div>

          <div tw="flex flex-col w-full relative md:(justify-center px-8)">
            <div tw="w-full relative md:(w-full)">
              <BeforeAfterSlider
                beforeImage={
                  photos[0].localFile.childImageSharp.gatsbyImageData
                }
                afterImage={photos[1].localFile.childImageSharp.gatsbyImageData}
              />
            </div>
            <DesktopTestimonialCard
              testimonial={testimonial}
              client={client}
              location={location}
              markdown={markdown}
              photo={photos?.[2]?.localFile.childImageSharp.gatsbyImageData}
            />
          </div>
          <div tw="border-t border-dotted border-emerald-200 pl-4 mt-4 py-8 max-w-lg md:(hidden)">
            <blockquote>
              <div>
                {markdown &&
                  renderRichText(markdown, {
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => {
                        if (node.nodeType === 'paragraph') {
                          return (
                            <p tw="font-thin text-xl leading-snug text-gray-700 max-w-xs">
                              {children}
                            </p>
                          )
                        }
                      },
                    },
                  })}
              </div>
              <footer tw="mt-6 flex items-center">
                <div tw="w-9 h-9 overflow-hidden rounded-full">
                  <GatsbyImage
                    alt="kitchen design"
                    image={
                      photos?.[2]?.localFile.childImageSharp.gatsbyImageData
                    }
                  />
                </div>
                <div tw="font-light pl-2">
                  <p tw="text-sm text-gray-500">{client}</p>
                  <p tw="text-xs text-gray-400">{location}</p>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </Section>
  )
}

export { Renderings }
