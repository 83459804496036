import React from 'react'

import 'twin.macro'
import { navigate } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import { MxButton as Button } from '../components/section-components'
import { BeforeAfterSlider } from '../homepage/renderings'
import { Section } from '../section'

interface Props {
  afterImage?: IGatsbyImageData
  beforeImage?: IGatsbyImageData
  buttonLink?: string
  buttonText?: string
  description?: string
  title?: string
}
export const BeforeAfterSection = ({
  afterImage,
  beforeImage,
  buttonLink,
  buttonText,
  description,
  title,
}: Props) => {
  return (
    <Section padding="normal">
      <div tw="relative px-6 bg-gray-700/5 md:(px-8) lg:(px-20)">
        <div tw="flex flex-col justify-around">
          <div tw="py-8 w-full text-gray-600 md:(py-12 px-8) lg:(px-20)">
            <p tw="text-center mx-auto text-3xl font-thin leading-tighter max-w-md md:(pr-8 text-4xl)">
              {title}
            </p>
            <p tw="mx-auto text-center text-lg font-light leading-normal max-w-md pt-5 md:(pr-8)">
              {description}
            </p>
          </div>
          <div tw="flex flex-col w-full relative md:(justify-between px-8) lg:(px-20)">
            <div tw="w-full relative self-end md:(w-full)">
              <BeforeAfterSlider
                beforeImage={beforeImage as IGatsbyImageData}
                afterImage={afterImage as IGatsbyImageData}
              />
            </div>
          </div>
          <div tw="flex flex-col w-full relative text-center md:(px-8) lg:(px-20)">
            <div>
              <Button
                tw="mt-10 mb-20"
                color="default"
                onClick={() => {
                  navigate(buttonLink ?? '')
                }}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default BeforeAfterSection
