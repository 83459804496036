import React from 'react'

import { motion } from 'framer-motion'
import { navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import tw from 'twin.macro'

import { FormContactSource } from 'types/crm'

import { SubmitCompass } from './submit-compass'
import { settings as defaultSettings } from '../compass/slider'
import {
  MxButton as Button,
  MxSectionContainer,
  MxSectionDescription,
  MxSectionFirstContainer,
  MxSectionSecondContainer,
  MxSectionTextContainer,
  MxSectionTitle,
} from '../components/section-components'
import { Section } from '../section'

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeOut', delay: 0.25, duration: 0.75 },
  },
  hidden: { opacity: 0, y: 75 },
}

interface Props {
  buttonLink?: string
  buttonText?: string
  description?: string
  image?: IGatsbyImageData
  imageUrl?: any
  includeButton?: boolean
  includeInput?: boolean
  isLeft?: boolean
  title?: string
}
export const SectionContent = ({
  buttonLink,
  buttonText,
  description,
  image,
  imageUrl,
  includeButton = true,
  includeInput = false,
  isLeft = false,
  title,
}: Props) => {
  return (
    <Section padding="top">
      <motion.div initial="hidden" animate="visible" variants={variants}>
        <MxSectionContainer tw="text-gray-600 font-light">
          <MxSectionFirstContainer
            css={[isLeft && tw`md:(!col-start-9 !col-end-17)`]}
          >
            <MxSectionTextContainer
              tw="md:mb-8"
              css={[
                isLeft && tw`!px-4 !mb-8 md:(!gap-y-6 !pr-4 !pl-0) lg:!pr-10`,
              ]}
            >
              <MxSectionTitle tw="max-w-md md:max-w-md">{title}</MxSectionTitle>
              <MxSectionDescription tw="max-w-md">
                {description}
              </MxSectionDescription>
              {includeButton && (
                <Button
                  tw="my-8 md:mb-0"
                  color="default"
                  onClick={() => {
                    navigate(buttonLink ?? '')
                  }}
                >
                  {buttonText}
                </Button>
              )}
              {includeInput && (
                <SubmitCompass
                  contactSource={FormContactSource.COMPASS}
                  buttonText={buttonText}
                />
              )}
            </MxSectionTextContainer>
          </MxSectionFirstContainer>
          <MxSectionSecondContainer
            tw="mb-auto mt-3 row-start-auto col-span-full grid grid-cols-8 gap-y-3 gap-x-3 md:(gap-y-6 pr-4) lg:pr-10"
            css={[
              isLeft &&
                tw`md:(!pl-4 !col-start-1 !col-end-9 !row-start-1) lg:!pl-10`,
            ]}
          >
            <div tw="col-start-1 col-end-9 relative md:mt-0">
              {!imageUrl ? (
                <GatsbyImage
                  alt="kitchen design"
                  tw="md:(h-full)"
                  image={image as IGatsbyImageData}
                />
              ) : (
                <img src={imageUrl} />
              )}
            </div>
          </MxSectionSecondContainer>
        </MxSectionContainer>
      </motion.div>
    </Section>
  )
}

export type AfterSliderProps = {
  localFile?: {
    childImageSharp?: {
      gatsbyImageData?: IGatsbyImageData
    }
  }
}

export class AfterSlider extends React.Component<{
  options: AfterSliderProps[]
}> {
  render = () => {
    const settings = {
      ...defaultSettings,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      adaptiveHeight: false,
      variableWidth: false,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            adaptiveHeight: false,
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            adaptiveHeight: false,
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 1,
            swipe: true,
            swipeToSlide: true,
          },
        },
        {
          breakpoint: 480,
          adaptiveHeight: false,
          variableWidth: false,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: true,
            swipeToSlide: true,
          },
        },
      ],
    }

    return (
      <div tw="mx-auto md:(w-full)">
        <Slider {...settings}>
          {this.props.options.map((option, index) => {
            return (
              <div tw="relative p-2" key={index}>
                <div tw="relative space-y-3">
                  <div tw="overflow-hidden relative">
                    <div tw="absolute top-0 left-0 right-0 bottom-0 w-full h-full z-10" />
                    <GatsbyImage
                      tw="h-full w-full"
                      alt="kitchen design"
                      image={
                        option.localFile?.childImageSharp
                          ?.gatsbyImageData as IGatsbyImageData
                      }
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }
}
