import React from 'react'

import { navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import 'twin.macro'

import { AfterSlider, AfterSliderProps } from './section-content'
import { MxButton as Button } from '../components/section-components'
import { Section } from '../section'

interface Props {
  afterImages?: AfterSliderProps[]
  beforeImage?: IGatsbyImageData
  buttonLink?: string
  buttonText?: string
  description?: string
  title?: string
}
export const BeforeAfterGallery = ({
  afterImages,
  beforeImage,
  buttonLink,
  buttonText,
  description,
  title,
}: Props) => {
  return (
    <Section padding="bottom">
      <div tw="relative">
        <div tw="flex flex-col justify-center m-auto">
          <div tw="w-full py-8 px-3 mx-auto text-gray-700 md:(px-0 text-center)">
            <h2 tw="font-thin leading-tighter text-3xl md:(text-4xl)">
              {title}
            </h2>
            <p tw="text-lg max-w-md font-light leading-normal mt-8 md:(mx-auto)">
              {description}
            </p>
            <Button
              tw="mt-8"
              color="default"
              onClick={() => {
                navigate(buttonLink ?? '')
              }}
            >
              {buttonText}
            </Button>
          </div>
          <div tw="w-full md:(flex flex-row)">
            <div tw="w-full md:(pl-4 mt-0 w-1/2)">
              <p tw="p-2">Before</p>
              <GatsbyImage
                alt="kitchen design"
                tw="m-2 md:(h-auto)"
                image={beforeImage as IGatsbyImageData}
              />
            </div>
            <div tw="w-full md:(pr-4 w-1/2)">
              <p tw="p-2">After</p>
              <AfterSlider options={afterImages ?? []} />
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default BeforeAfterGallery
