import React from 'react'

import { navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import 'twin.macro'

import { MxButton as Button } from '../components/section-components'
import { Section } from '../section'

type BenefitsProps = {
  comparable?: string
  kitchen?: string
  name?: string
  position?: string
  purchase?: string
  quote?: string
  sale?: string
}
interface Props {
  benefits?: BenefitsProps
  buttonLink?: string
  buttonText?: string
  description?: string
  image?: IGatsbyImageData
  title?: string
}
export const KitchenValue = ({
  benefits,
  buttonLink,
  buttonText,
  description,
  image,
  title,
}: Props) => {
  return (
    <Section padding="bottom">
      <div tw="relative bg-gray-900/10 px-3 py-8 md:p-8">
        <div tw="flex flex-col flex-col-reverse justify-center m-auto">
          <div tw="w-full">
            <div tw=" mx-auto md:(mt-0 max-w-3xl)">
              <div tw="relative overflow-hidden">
                <GatsbyImage
                  alt="kitchen design"
                  tw=" md:(h-full)"
                  image={image as IGatsbyImageData}
                />
                <div tw="relative overflow-hidden w-full bg-gray-900 md:(absolute right-0 left-0 bottom-0 bg-gray-900/60 h-52)">
                  <div tw="relative m-4 md:(absolute top-3 left-4 right-2 m-0)">
                    <p tw="text-2xl text-white leading-tight italic max-w-lg">
                      “{benefits?.quote}”
                    </p>
                    <div tw="relative capitalize font-sans font-light text-white md:border-l">
                      <div tw="md:pl-1 mt-2">
                        <div tw="text-base">{benefits?.name}</div>
                        <div tw="text-sm">{benefits?.position}</div>
                      </div>
                      <div tw="flex flex-col text-sm leading-tight mt-3 md:flex-row">
                        <div tw="w-full border-l my-3 pl-1 md:(w-3/12 border-0 my-0)">
                          Kitchen <br tw="hidden md:block" />
                          cost:
                          <p tw="font-thin text-base oldstyle-nums">
                            ${benefits?.kitchen}
                          </p>
                        </div>
                        <div tw="w-full border-l my-3 pl-1 md:(w-3/12 my-0)">
                          Comparable <br tw="hidden md:block" />
                          kitchen:
                          <p tw="font-thin text-base oldstyle-nums">
                            ${benefits?.comparable}
                          </p>
                        </div>
                        <div tw="w-full border-l my-3 pl-1 md:(w-3/12 my-0)">
                          Purchase <br tw="hidden md:block" />
                          price:
                          <p tw="font-thin text-base oldstyle-nums">
                            ${benefits?.purchase}
                          </p>
                        </div>
                        <div tw="w-full border-l my-3 pl-1 md:(w-3/12 my-0) ">
                          Sale <br tw="hidden md:block" />
                          price:
                          <p tw="font-thin text-base oldstyle-nums">
                            ${benefits?.sale}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div tw="w-full mx-auto text-center text-gray-700">
            <h2 tw="font-thin leading-tighter text-3xl md:(text-4xl leading-tighter)">
              {title}
            </h2>
            <p tw="text-lg max-w-md font-light leading-normal mt-8 mx-auto">
              {description}
            </p>
            <Button
              tw="my-8 md:mb-8"
              color="default"
              onClick={() => {
                buttonLink ? navigate(buttonLink) : undefined
              }}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default KitchenValue
