import React, { useEffect } from 'react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import { useCompassItems } from 'content-queries/compass/compass'
import fireEvent from 'context/tracking/events'
import BeforeAfterGallery from 'mx/compass/before-after-gallery'
import BeforeAfterSection from 'mx/compass/before-after-section'
import BrandsSection from 'mx/compass/brands-section'
import KitchenValue from 'mx/compass/kitchen-value'
import SalesQuote from 'mx/compass/sales-quote'
import { SectionContent } from 'mx/compass/section-content'
import SubmitBottom from 'mx/compass/submit-bottom'
import Layout from 'mx/layout'

const CompassPage = () => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Compass',
    })
  }, [])

  const { data: compassItemsData } = useCompassItems()
  const compassItemsCollection: any[] = compassItemsData.map((n: any) => ({
    actions: n.node.actions,
    description: n.node.description,
    image: n.node.image.localFile.childImageSharp.gatsbyImageData,
    sections: n.node.sectionModules,
    title: n.node.title,
  }))

  return (
    <Layout>
      <BrandsSection
        formLogo={compassItemsCollection[0].sections[0]?.images[1]?.file.url}
        partnerLogo={compassItemsCollection[0].sections[0]?.images[0]?.file.url}
      />
      <SectionContent
        description={compassItemsCollection[0].description}
        image={compassItemsCollection[0].image}
        includeButton={false}
        includeInput={true}
        title={compassItemsCollection[0].title}
      />
      <SectionContent
        description={
          compassItemsCollection[0].sections[1]?.moduleMisc.description
        }
        image={
          compassItemsCollection[0].sections[1]?.images[0]?.localFile
            .childImageSharp.gatsbyImageData
        }
        includeButton={false}
        isLeft={compassItemsCollection[0].sections[1]?.moduleMisc.isLeft}
        title={compassItemsCollection[0].sections[1]?.moduleTitle}
      />
      <SectionContent
        description={
          compassItemsCollection[0].sections[2]?.moduleMisc.description
        }
        imageUrl={compassItemsCollection[0].sections[2]?.images[0]?.file.url}
        includeButton={false}
        includeInput={true}
        title={compassItemsCollection[0].sections[2]?.moduleTitle}
      />
      <BeforeAfterSection
        afterImage={
          compassItemsCollection[0].sections[3]?.images[1]?.localFile
            .childImageSharp.gatsbyImageData
        }
        beforeImage={
          compassItemsCollection[0].sections[3]?.images[0]?.localFile
            .childImageSharp.gatsbyImageData
        }
        buttonLink={
          compassItemsCollection[0].sections[3]?.moduleMisc.action.url
        }
        buttonText={
          compassItemsCollection[0].sections[3]?.moduleMisc.action.text
        }
        description={
          compassItemsCollection[0].sections[3]?.moduleMisc.description
        }
        title={compassItemsCollection[0].sections[3]?.moduleTitle}
      />
      <BeforeAfterGallery
        afterImages={
          compassItemsCollection[0].sections[4]?.contentSlots[0].photos
        }
        beforeImage={
          compassItemsCollection[0].sections[4]?.images[0]?.localFile
            .childImageSharp.gatsbyImageData
        }
        buttonLink={
          compassItemsCollection[0].sections[4]?.moduleMisc.action.url
        }
        buttonText={
          compassItemsCollection[0].sections[4]?.moduleMisc.action.text
        }
        description={
          compassItemsCollection[0].sections[4]?.moduleMisc.description
        }
        title={compassItemsCollection[0].sections[4]?.moduleTitle}
      />
      <KitchenValue
        benefits={compassItemsCollection[0].sections[5]?.moduleMisc.benefits}
        buttonLink={
          compassItemsCollection[0].sections[5]?.moduleMisc.action.url
        }
        buttonText={
          compassItemsCollection[0].sections[5]?.moduleMisc.action.text
        }
        description={
          compassItemsCollection[0].sections[5]?.moduleMisc.description
        }
        image={
          compassItemsCollection[0].sections[5]?.images[0]?.localFile
            .childImageSharp.gatsbyImageData
        }
        title={compassItemsCollection[0].sections[5]?.moduleTitle}
      />
      <SalesQuote
        name={compassItemsCollection[0].sections[8]?.moduleMisc.name}
        position={compassItemsCollection[0].sections[8]?.moduleMisc.position}
        quote={compassItemsCollection[0].sections[8]?.moduleMisc.quote}
      />
      <SectionContent
        description={
          compassItemsCollection[0].sections[6]?.moduleMisc.description
        }
        image={
          compassItemsCollection[0].sections[6]?.images[0]?.localFile
            .childImageSharp.gatsbyImageData
        }
        includeButton={false}
        includeInput={true}
        title={compassItemsCollection[0].sections[6]?.moduleTitle}
      />
      <SectionContent
        description={
          compassItemsCollection[0].sections[7]?.moduleMisc.description
        }
        image={
          compassItemsCollection[0].sections[7]?.images[0]?.localFile
            .childImageSharp.gatsbyImageData
        }
        includeButton={false}
        isLeft={compassItemsCollection[0].sections[7]?.moduleMisc.isLeft}
        title={compassItemsCollection[0].sections[7]?.moduleTitle}
      />
      <SubmitBottom
        description={
          compassItemsCollection[0].sections[9]?.moduleMisc.description
        }
        quote={compassItemsCollection[0].sections[9]?.moduleMisc.quote}
        title={compassItemsCollection[0].sections[9]?.moduleTitle}
      />
    </Layout>
  )
}
export default CompassPage

export const Head = () => (
  <SEO
    description="We partnered with Compass and use smartphone photos of the existing kitchen to create lifelike renders of what the new modern kitchen will look like."
    path="/compass"
    title="Compass"
  />
)
